<template>
<loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading 
          :heading="equipment.name">
          Updated {{ equipment.updated_at | asCalendar }}
        </page-heading>  
      </column>
      <column class="is-narrow">
        <tabs>
          <tab-link name="equipment-manager" :params="{ equipment: equipment.uuid }">Overview</tab-link>
          <tab-link name="equipment-checklists" :params="{ equipment: equipment.uuid }">Checklists</tab-link>
          <tab-link name="equipment-checklist-schedule" :params="{ equipment: equipment.uuid }">Schedule</tab-link>
          <tab-link v-if="!$root.isPassiveUser()" name="edit-equipment" :params="{ equipment: equipment.uuid }">Preferences</tab-link>
        </tabs>
      </column>
    </columns>
    <router-view />
</loader>    
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    
    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipment/loadEquipment', this.$route.params.equipment)
        this.loading = false
    },

    computed: {
        ...mapGetters('equipment', [
            'equipment'
        ])
    }

}
</script>